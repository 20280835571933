<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col key="listAgendamentos" :cols="12">
          <v-card v-if="agendamentos">
            <v-card-title>
              Agendamentos
              <v-btn
                title="Criar Agendamento"
                class="mx-2"
                fab

                small
                color=primary
                @click="selected={};addAgendamento=true">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchAgendamento"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar">
              </v-text-field>
            </v-card-title>
            <v-card-text>
              <v-menu
                ref="menuDataInicio"
                v-model="menuDataInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">

                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2"
                    color=primary
                    small
                    v-on="on">
                    Data Inicial:
                    {{
                      dataInicio.split("-")[2] +
                      "/" +
                      dataInicio.split("-")[1] +
                      "/" +
                      dataInicio.split("-")[0]
                    }}
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dataInicio"
                  locale="pt-pt"
                  no-title
                  @input="menuDataInicio = false">
                </v-date-picker>
              </v-menu>
              <v-menu
                ref="menuDataFim"
                v-model="menuDataFim"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2"
                    color=primary
                    small
                    v-on="on">
                    Data Final:
                    {{
                      dataFim.split("-")[2] +
                      "/" +
                      dataFim.split("-")[1] +
                      "/" +
                      dataFim.split("-")[0]
                    }}
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dataFim"
                  locale="pt-pt"
                  no-title
                  @input="menuDataFim = false">
                </v-date-picker>
              </v-menu>
              <v-btn
                class="mx-2"
                v-bind:class="{ buttonOnClass: showCompleted}"

                small
                @click="filterCompleted(!showCompleted)">
                <v-icon class="mr-2">
                mdi-calendar-check-outline
                </v-icon>
                Concluidos
              </v-btn>
              <v-btn
                class="mx-2"
                v-bind:class="{ buttonOnClass: showCanceled}"

                small
                @click="filterCanceled(!showCanceled)">
                <v-icon class="mr-2">
                mdi-calendar-remove-outline
                </v-icon>
                Cancelados
              </v-btn>
            </v-card-text>
            <v-data-table
              :item-class="getColor"
              v-model="agendamentoSelected"
              :headers="agendamentoHeader"
              :search="searchAgendamento"
              :custom-filter="filtroDaBusca"
              :items="agendamentoDataFiltered"
              :loading="loaders.agendamentos"
              loading-text="A carregar..."
              :single-expand="true"
              :single-select="true"
              :expanded.sync="expanded"
              show-expand
              item-key="id"
              no-data-text="Sem resultados"
              no-results-text="Sem resultados"
              hide-default-footer>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="(headers.length)"
                  :class="getColor(item)">
                  <v-row class="ma-0">
                    <v-col class="text-center" :cols="3">
                      <b>Data de Recolha:</b>
                      <div>
                        {{ item.dataHoraRecolha ? dateToYYYYMMDDHHMM(item.dataHoraRecolha) : '' }}
                      </div>
                    </v-col>
                    <v-col class="text-center" :cols="2">
                      <b>Camião:</b>
                      <div>
                        {{ item.camiao ? item.camiao.nome : '' }}
                      </div>
                    </v-col>
                    <v-col class="text-center" :cols="2">
                      <b>Motorista:</b>
                      <div>
                        {{ item.user ? item.user.nome + ' ' + item.user.apelido : '' }}
                      </div>
                    </v-col>
                    <v-col class="text-center" :cols="5">
                      <b>Observações da Recolha:</b>
                      <div>
                        {{ item.obsRecolha }}
                      </div>
                    </v-col>
                  </v-row>
                 </td>
              </template>
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
              <template v-slot:[`item.dataHoraAgendamento`]="{ item }">
                {{ dateToYYYYMMDDHHMM(item.dataHoraAgendamento) }}
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ dateToYYYYMMDDHHMM(item.created_at) }}
              </template>
              <template v-slot:[`item.editar`]="{ item }">
                <v-btn
                  v-if="item.recolhido != true && item.cancelado != true "
                  class="mx-2"
                  fab

                  small
                  color=primary
                  @click="editarAgendamento(item)">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
               <template v-slot:[`item.cancelar`]="{ item }">
                <v-btn
                  v-if="item.recolhido != true && item.cancelado != true"
                  class="mx-2"
                  fab

                  small
                  color=primary
                  @click="showDialogCancel=true; dialogCancelId=item.id">
                  <v-icon>
                  mdi-delete-forever
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br><br>
    <div class="text-center">
      <v-dialog v-model="addAgendamento" style="z-index:9999" width="500">
        <v-card :disabled = "loaders.savingAgendamento">
          <v-card-title class="headline grey lighten-2" primary-title>{{this.selected.id ? "Editar" : "Criar" }} agendamento</v-card-title>
          <v-card-text>
            <v-form ref="formAgendamentos" lazy-validation>
            <v-text-field
               v-model="selected.nome"
               label="Nome"
               :rules="[rules.required]">
               </v-text-field>
               <v-text-field
               v-model="selected.morada"
               label="Morada"
               :rules="[rules.required]">
              </v-text-field>
             <v-select
                v-model="selected.freguesia"
                :items="freguesias"
                item-text="designacao"
                item-value="id"
                label="Freguesia"
                :rules="[rules.required]">
              </v-select>
              <v-text-field v-model="selected.contacto" label="Contacto" ></v-text-field>
            <v-row dense>
              <v-col key="date" :cols="6">
                <v-menu
                  ref="menuDate"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected.dataAgendamento"
                      label="Data Agendamento"
                      readonly
                      v-bind="attrs"
                      v-on="on">
                    </v-text-field>
                  </template>
                <v-date-picker
                  v-model="selected.dataAgendamento"
                  no-title
                  scrollable>
                  <v-spacer>
                  </v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menuDate = false">Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuDate.save(selected.dataAgendamento)">OK
                    </v-btn>
                </v-date-picker>
                </v-menu>
              </v-col>
              <v-col key="hour" :cols="6">
                <v-menu
                  ref="menuHour"
                  v-model="menuHour"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected.horaAgendamento"
                      label="Hora Agendamento"
                      readonly
                      v-bind="attrs"
                      v-on="on">
                    </v-text-field>
                  </template>
                  <v-time-picker
                    v-model="selected.horaAgendamento"
                    no-title
                    format="24hr"
                    scrollable>
                  <v-spacer>
                  </v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuHour = false">Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuHour.save(selected.horaAgendamento)">OK
                  </v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
              <v-text-field
              v-model="selected.obsAgendamento"
              label="Observações"
              :rules="[rules.required]">
              </v-text-field>
              <v-btn
                color="success"
                class="mr-4"
                @click="validateForm"
                :loading = "loaders.savingAgendamento"
                :disabled = "loaders.savingAgendamento">
                Salvar
              </v-btn>
              <v-btn color="error" class="mr-4" @click="addAgendamento = false">
                Cancelar
              </v-btn>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </div>
    <dialog-confirmation
      :showDialog = "showDialogCancel"
      :question = "'Confirma cancelamento?'"
      :message="'Não vai poder recuperar este agendamento!'"
      @hideDialog = "showDialogCancel=false"
      @responseDialog = "handleReponseCancel">
    </dialog-confirmation>
  </div>
</template>

<script>
/* eslint-disable */
//import { icon, latLng } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LGeoJson,
  LIcon,
  LPolyline,
} from "vue2-leaflet";

import mixin from '../plugins/mixin';
import DialogConfirmation from '../components/DialogConfirmation.vue';

export default {
  name: "Agendamentos",
  mixins: [mixin],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LGeoJson,
    LIcon,
    LPolyline,
    DialogConfirmation,
  },
  data: () => ({
    API_URL: process.env.VUE_APP_API_URL,
    showPass: false,
    showDialogCancel: false,
    dialogCancelId: 0,
    addAgendamento: false,
    menuDataInicio: false,
    menuDataFim: false,
    showCompleted: false,
    showCanceled: false,
    agendamentoSelected: [],
    agendamentos: [],
    agendamentoData: [],
    agendamentoDataFiltered: [],
    searchAgendamento: "",
    dataInicio: null,
    dataFim: new Date().toISOString().substr(0, 10),
    selected: {},
    expanded: [],
    freguesias: [],
    menuDate: false,
    menuHour: false,
    loaders: {
      agendamentos: false,
      savingAgendamento: false,
    },
    rules: {
      required: (value) => !!value || "Preenchimento obrigatório",
    },
    agendamentoHeader: [
      { text: "Nome", value: "nome", align: 'center' },
      { text: "Morada", value: "morada", align: 'center' },
      { text: "Freguesia", value: "freguesia.designacao", align: 'center' },
      { text: "Contacto", value: "contacto", align: 'center' },
      { text: "Criado", value: "created_at", align: 'center'},
      { text: "Agendado", value: "dataHoraAgendamento", align: 'center'},
      { text: "Observações", value: "obsAgendamento", align: 'center' },
      { text: "Editar", value: "editar", sortable: true, align: 'center' },
      { text: "Cancelar", value: "cancelar", sortable: true, align: 'center' },
    ],
  }),
  methods: {
    filtroDaBusca(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        item.valoresDePesquisa.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').indexOf(search.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')) !== -1
       // nomalize(NFD) e replace() estao a remover tudo que nao for 0-9 e o que nao for A-Z a-z, ou seja, removendo acentos e caracteres especiais no campo de pesquisa//
      );
    },

    getColor: (item) => item.recolhido == false ? "rowRed" : "",
    editarAgendamento(item) {
      this.selected = item;
      this.addAgendamento = true;
    },
    handleReponseCancel: function(response) {
      if(response) {
        this.$http
        .put("agendamentos/" + this.dialogCancelId, {cancelado: true}, {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.getAgendamentos();
        })
        .catch((error) => {});
        this.dialogCancelId = 0;
      }
    },
    validateForm() {
      if (this.$refs.formAgendamentos.validate()) {
        this.saveAgendamento();
      }
    },
    saveAgendamento() {
      this.loaders.savingAgendamento = true;
      var dataHoraAgendamento = new Date(this.selected.dataAgendamento + " " + this.selected.horaAgendamento);
      this.selected.dataHoraAgendamento = dataHoraAgendamento;
      if (this.selected.id){
        this.$http
          .put("agendamentos/" + this.selected.id, this.selected, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then((response) => {
            this.$refs.formAgendamentos.reset();
            this.getAgendamentos();
            this.addAgendamento = false;
            this.loaders.savingAgendamento = false;
          })
          .catch((error) => {
            console.log(error);
            this.loaders.savingAgendamento = false;
          });
        } else {
          this.$http
            .post("agendamentos/", this.selected, {
              headers: {
                Authorization: `Bearer ${this.getObjeto("token")}`,
              },
            })
            .then((response) => {
              this.$refs.formAgendamentos.reset();
              this.getAgendamentos();
              this.addAgendamento = false;
              this.loaders.savingAgendamento = false;
            })
            .catch((error) => {
              console.log(error);
              this.loaders.savingAgendamento = false;
            });
        }
    },
    getAgendamentos() {
      this.loaders.agendamentos = true;
      var inicio = new Date(this.dataInicio);
      inicio.setDate(inicio.getDate());
      var fim = new Date(this.dataFim);
      fim.setDate(fim.getDate() + 1);

      this.$http
        .get(
          "agendamentos/count?created_at_gte=" +
            inicio.toISOString() +
            "&created_at_lt=" +
            fim.toISOString(),
          {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          }
        )
        .then((qtsAgendamentos) => {
           for (
             let index = 0;
             //GraphQL foi configurado para enviar no máximo 800 resultados
             //Strapi -> app/config/plugins.js
             index < Math.round(qtsAgendamentos.data / 800) + 1;
             index++){
             this.$http
               .get("graphql?query={ \
                 agendamentos(start: "+(index*800)+", sort: \"created_at:desc\", \
                 where:{created_at_gte:\""+inicio.toISOString()+"\",created_at_lt:\""+fim.toISOString()+"\"}) \
                 {id, nome, morada, contacto, obsAgendamento, obsRecolha, recolhido, cancelado, \
                 dataHoraAgendamento, created_at, freguesia {designacao}, camiao {nome}, \
                 dataHoraRecolha, user {nome, apelido}}}",
                 {
                   headers: {
                     Authorization: `Bearer ${this.getObjeto("token")}`,
                   },
                 }
               )
               .then((response) => {
                  this.agendamentoData = response.data.data.agendamentos;
                  this.agendamentoData.forEach((i) => {
                    i.valoresDePesquisa =
                      i.nome + " " + i.morada + " " + i.contacto + " " + i.obsAgendamento +
                      " " + i.freguesia.designacao + " " + (i.camiao?i.camiao.nome:'') + " " +
                      " " + (i.user?(i.user.nome + " " + i.user.apelido):'')
                  })
                  this.filterCompleted(this.showCompleted);
                  this.loaders.agendamentos = false;
               })
               .catch((error) => {
                 console.log(error);
                 this.loaders.agendamentos = false;
               })
           }
        });
    },
    filterCompleted(show){
      this.showCanceled = false
      this.showCompleted = show;

      if(show) {
        this.agendamentoDataFiltered = this.agendamentoData.filter(
        (item) => item.recolhido == true)
      } else {
        this.agendamentoDataFiltered = this.agendamentoData.filter(
          (item) => item.recolhido != true && item.cancelado != true
        );
      }
    },
    filterCanceled(show){
      this.showCompleted = false
      this.showCanceled = show;
      if(show) {
        this.agendamentoDataFiltered = this.agendamentoData.filter(
        (item) => item.cancelado == true)
      } else {
        this.agendamentoDataFiltered = this.agendamentoData.filter(
          (item) => item.cancelado != true && item.recolhido != true
        );
      }
    },
    getObjeto(nome) {
      return localStorage.getItem(nome)
        ? JSON.parse(localStorage.getItem(nome))
        : null;
    },
    setObjeto(nome, objeto) {
      return localStorage.setItem(nome, JSON.stringify(objeto));
    },
    convertData(data) {
      return new Date(data).toLocaleString();
    },
  },
  created() {
    this.getFreguesias().then((resp) => {
      this.freguesias = resp.data.data.freguesias
    });
    this.getAgendamentos();
    this.dataInicio = this.subtractDays(30).format('YYYY-MM-DD');
  },

  watch: {
    dataInicio: function () {
      this.getAgendamentos();
    },
    dataFim: function () {
      this.getAgendamentos();
    },
  },
};
</script>
<style>
  .rowRed{
    background-color: rgb(71, 24, 24) !important;
  }
</style>
<style scoped>
  input:required:invalid {
    border-color: #FF0000;
    box-shadow: inset 0 1px 1px rgba(0,0,0, 0.075), 0 0 8px rgba(225, 0, 0, 0.6);
  }
  .buttonOnClass{
    background-color: #7366aa !important;
  }
</style>